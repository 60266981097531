import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { apiUrl } from "../config.json";

axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("tokenSunMarketing");
    if (accessToken) {
      config.headers["x-auth-token"] = accessToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(null, (error) => {
  const originalRequest = error.config;
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  const MySwal = withReactContent(Swal);

  if (!expectedError) {
    MySwal.fire({
      icon: "error",
      title: "Oops...",
      text: "An unexpected error occured.",
    });
  }

  let refreshToken = localStorage.getItem("refreshTokenSunMarketing");

  if (
    refreshToken &&
    error.response.status === 401 &&
    error.response.data === "JWT Expired" &&
    !originalRequest._retry
  ) {
    originalRequest._retry = true;
    return axios
      .post(`${apiUrl}/v1/auth/refreshTokenMarketing`, {
        refreshToken: refreshToken,
      })
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("tokenSunMarketing", res.data.token);
          localStorage.setItem(
            "refreshTokenSunMarketing",
            res.data.refreshToken
          );

          return axios(originalRequest);
        }
      });
  }

  return Promise.reject(error);
});

// function setJwt(token) {
//   axios.defaults.headers.common["x-auth-token"] = token;
// }

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  //setJwt,
};
